<template>
  <div>
    <base-material-card
      icon="fa4 fa-bank"
      class="mt-12"
      style="position: relative;"
    >
      <v-tabs
        v-model="tab"
        :style="isXs ? 'position: absolute;top:2px;left:100px;width: auto;' : 'position: absolute;top:2px;left:140px;width: auto;'"
      >
        <v-tab>
          <div class="card-title font-weight-light">
            编辑日志
          </div>
        </v-tab>
        <v-tab>
          <div class="card-title font-weight-light">
            审核日志
          </div>
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-container>
            <div class="line_box">
              <v-timeline dense>
                <v-timeline-item
                  v-for="(log, index) in logs"
                  :key="index"
                  class="mb-4"
                  small
                >
                  <v-row>
                    <v-col cols="5">
                      <v-chip
                        class="white--text ml-0"
                        color="purple"
                        label
                        small
                      >
                        修改
                      </v-chip>
                      <template v-if="log[0].user">
                        {{ log[0].user.nickname }}
                      </template>
                    </v-col>
                    <v-col
                      cols="5"
                      class="text-right"
                    >
                      {{ log[0].createdAt | dateformat }}
                    </v-col>
                    <v-col cols="10">
                      <v-card
                        class="mx-auto pa-5"
                        outlined
                      >
                        <p
                          v-for="(c, i) in log"
                          :key="i"
                          class="ma-0"
                        >
                          {{ c.name }}：<span class="red--text">{{ c.value }}</span> ----> <span class="green--text">{{ c.valueAfter }}</span>
                        </p>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-timeline-item>
              </v-timeline>
            </div>
          </v-container>
        </v-tab-item>
        <v-tab-item>
          <v-list three-line>
            <v-list-item
              v-for="(processes, index) in processesList"
              :key="index"
              two-line
            >
              <v-list-item-content>
                <v-list-item-title>
                  <span
                    style="color:#000000"
                  >
                    {{ processes.roleName }}</span>
                  <v-chip
                    v-if="processes.status === 10"
                    class="ma-2"
                    color="success"
                    text-color="white"
                    small
                  >
                    <v-icon
                      left
                      small
                    >
                      fa fa-check
                    </v-icon>
                    审核通过
                  </v-chip>
                  <v-chip
                    v-else-if="processes.status === -5"
                    class="ma-2"
                    color="error"
                    text-color="white"
                    small
                  >
                    <v-icon
                      left
                      small
                    >
                      fa fa-close
                    </v-icon>
                    审核驳回
                  </v-chip>
                  <v-chip
                    v-else-if="processes.status === -10"
                    class="ma-2"
                    color="orange"
                    text-color="white"
                    small
                  >
                    <v-icon
                      left
                      small
                    >
                      fa fa-exclamation
                    </v-icon>
                    彻底驳回
                  </v-chip>
                  <v-chip
                    v-else
                    class="ma-2"
                    color="success"
                    text-color="white"
                    small
                  >
                    <v-icon
                      left
                      small
                    >
                      fa fa-ellipsis-h
                    </v-icon>
                    正在审核
                  </v-chip>

                  <span
                    v-if="processes.auditAt"
                    style="color:#666666"
                    class="mr-2"
                  >{{ processes.auditAt | dateformat }}</span>
                </v-list-item-title>
                <v-list-item-subtitle>
                  <v-alert
                    v-if="processes.status === -10 || processes.status === -5"
                    class="pa-0"
                    dense
                    text
                    type="error"
                  >
                    驳回原因：{{ processes.message }}
                  </v-alert>
                  <v-alert
                    v-else-if="processes.status === 0"
                    dense
                    class="pa-0"
                    text
                    type="success"
                  >
                    审核中，请耐心等待。
                  </v-alert>
                  <v-alert
                    v-else
                    dense
                    class="pa-0"
                    text
                    type="success"
                  >
                    说明：{{ processes.message }}
                  </v-alert>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-tab-item>
      </v-tabs-items>
    </base-material-card>
  </div>
</template>

<script>
  import userPhoto from '../../../assets/user_photo.jpg'
  export default {
    props: {
      reportId: {
        type: String,
        default: null,
      },
      formId: {
        type: String,
        default: null,
      },
    },
    data () {
      return {
        logs: [],
        userPhoto: userPhoto,
        tab: null,
        processesList: [],
        // 外商
        wsFormId: 'bce0f632-42a8-4cf7-a30c-e5c6f00c6e62',
        // 推介
        tjFormId: 'defdcd74-fe79-4906-b3aa-dbdb0ce55afe',
        isXs: false,
      }
    },
    watch: {
      '$vuetify.breakpoint.name': {
        handler (name) {
          if (name === 'xs') {
            this.isXs = true
          } else if (name === 'sm') {
            this.isXs = false
          } else {
            this.isXs = false
          }
        },
        deep: true,
        // 代表在wacth里声明了这个方法之后立即先去执行handler方法
        immediate: true,
      },
    },
    created () {
      this.getLog()
      this.getProcessesList()
    },
    methods: {
      //! 获取项目日志
      async getLog () {
        const logsData = await this.$axios.get(`/reports/${this.reportId}/change-logs`)
        const logs = logsData.data.data.reverse()
        const groupBy = () => {
          return logs.reduce((pre, current, index) => {
            pre[current.version] = pre[current.version] || []
            pre[current.version].unshift(current)
            return pre
          }, {})
        }
        const logsObj = groupBy()
        const logsArr = Object.keys(logsObj)
        const reverseLogsArr = logsArr.reverse()
        reverseLogsArr.map(val => {
          this.logs.push(logsObj[val])
        })
      },
      // 获取审核流程
      async getProcessesList () {
        const processes = await this.$axios.get(`/reports/${this.reportId}/processes`)
        processes.data.data.map((val, index) => {
          if (val.status === -5 || val.status === -10) {
            this.$swal({
              icon: 'warning',
              title: '项目需要待完善',
              width: 600,
              html:
                '待完善理由：' +
                '<h3 style="display: inline; color: #ff5252;">' + val.message + '</h3>' + '<p style="color: #ccc">(请修改后重新提交。)</p>',
              animation: false,
              showCancelButton: true,
              cancelButtonColor: '#4caf50',
              cancelButtonText: '知道了',
              confirmButtonColor: '#3085d6',
              confirmButtonText: '立即修改',
            })
              .then(res => {
                if (res.value) {
                  console.log(this.formId)
                  console.log(this.id)
                  this.$router.push({
                    path: '/report',
                    query: {
                      formId: this.formId,
                      id: this.reportId,
                    },
                  })
                }
              })
          }
        })
        const processesList = processes.data.data
        processesList.map(async val => {
          this.$set(val, 'roleName', await this.getMeInfo(val))
        })
        this.processesList = processesList
      },

      async getMeInfo (val) {
        var name = val.roleName
        if (this.formId === this.wsFormId) {
          switch (val.roleValue) {
            case 'ROLE_TZCJZX':
              name = '预审'
              break;
            case 'ROLE_TZCJCNBYJ':
              name = '初审'
              break;
            case 'ROLE_TZCJCFZRSHYJ':
              name = '终审'
              break;

            default:
              break;
          }
          return name
        } else {
          switch (val.roleValue) {
            case 'ROLE_TZCJZX':
              name = '省预审'
              break;
            case 'ROLE_TZCJCNBYJ':
              name = '省初审'
              break;
            case 'ROLE_TZCJCFZRSHYJ':
              name = '省终审'
              break;

            default:
              break;
          }
          return name
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
.line_box {
  max-height: 500px;
  overflow-x: hidden;
  overflow-y: scroll;
}
</style>
